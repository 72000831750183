// Contact Styles 

.contact-form--1 {
    label {
        display: block;
        margin-bottom: 0;
    }

    input,
    textarea {
        display: block;
        width: 100%;
        padding: 0 20px;
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        transition: all 0.3s ease;
        height: 50px;
        line-height: 46px;
        margin-bottom: 20px;
        outline: none;
        color: #1f1f25;
        font-size: 15px;
        letter-spacing: 0.1px;
        &:focus {
            border-color: $theme-color;
        }
    }
    textarea {
        height: 120px;
    }
}

.img-co{
    height: 490px;

    @media #{$md-layout} {
        height: auto;
    }
    @media #{$sm-layout} {
        height: auto;
    }
    }
/*==========================
    Contact Address 
============================*/
.msg{
    display: none;
}
.msgAppear{
    text-align: center;
    color: green;
    background: #fff;
    margin: 10px 0;
    display: block !important;
    font-size: 20px;
}
.titledemande{
    font-size: 38px !important;
    margin-bottom: 20px !important;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: 1.3px;
}
.titledemande:after {
    content: "";
    width: 100px;
    height: 3px;
    display: block;
    background: #f9004d;
    position: relative;
    left: 2px;
    top: 6px;
}
.descriptiondemande{
    font-size: 17px !important;
    letter-spacing: 0.8px;
}
.divdmd{
    display: inline-block !important;
    padding: 0 15px 0 0;
    vertical-align: text-top;
}
.titleIn{
    font-weight: 400;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    font-size: 17px;
}
.errmsg{
    display: none;
    color: #f9004d;
    font-size: 14px;
    margin-bottom: 10px;
}
.rn-address {
    margin-top: 30px;
    border: 1px solid #ebebeb;
    padding: 40px;
    border-radius: 10px;
    padding-top: 54px;
    background: #fff;
    padding-bottom: 50px;
    height: 185px;
    .icon {
        border: 1px solid #ebebeb;
        width: 78px;
        height: 78px;
        color: #f9004d;
        line-height: 73px;
        text-align: center;
        border-radius: 100%;
        background: #ffffff;
        font-size: 28px;
        position: absolute;
        top: -13px;
        left: 47px;
        @extend %transition;
    }
    .inner {
        h4 {
            &.title {
                font-size: 18px;
                font-weight: 700;
            }
        }
        p {
            font-size: 18px;
            margin-bottom: 0;
            color: rgba(29, 29, 36, 0.75);
            @media #{$lg-layout} {
                font-size: 16px;
            }
            @media #{$md-layout} {
                font-size: 16px;
            }
            @media #{$sm-layout} {
                font-size: 16px;
            }
            br {
                
                @media #{$lg-layout} {
                    display: none;
                }

                @media #{$md-layout} {
                    display: none;
                }

                @media #{$sm-layout} {
                    display: none;
                }
            }
            a {
                color: rgba(29, 29, 36, 0.75);
                @extend %transition;
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
    &:hover {
        .icon {
            background: $theme-color;
            border-color: $theme-color;
            color: #fff;
        }
    }
}

input,
textarea {
    @extend %transition;
}

.blog-comment-form .inner .rnform-group input:focus ,
.blog-comment-form .inner .rnform-group textarea:focus {
    border-color: $theme-color ;
}














