/*--------------------------
    Service Details Styles 
-----------------------------*/

.service-details-inner {
    .page-title {
        padding-left: 105px;
        h2 {
            &.title {
                font-size: 40px;
                line-height: 51px;
                margin-bottom: 14px;
            }
        }
        p {
            font-size: 24px;
            line-height: 30px;
            color: rgba(29, 29, 36, 0.75);
            margin-bottom: 0;
        }
    }
    .sercice-details-content {
        .thumb {
            .video-popup {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50% , -50%);
                border: 0 none;
            }
        }
        .details {
            p {
                color: rgba(29, 29, 36, 0.75);
                font-size: 18px;
                line-height: 30px;
                margin-bottom: 0;
                & + p {
                    margin-top: 30px;
                }
            }
            h4 {
                &.title {
                    font-size: 36px;
                    line-height: 40px;
                    font-weight: 500;
                    margin-top: 40px;
                    margin-bottom: 27px;
                    @media #{$sm-layout} {
                        font-size: 26px;
                        line-height: 38px;
                        margin-top: 26px;
                        margin-bottom: 18px;
                    }
                }
            }
            ul {
                &.liststyle {
                    li {
                        font-size: 18px;
                        line-height: 30px;
                        color: rgba(29, 29, 36, 0.75);
                        position: relative;
                        padding-left: 30px;
                        &::before {
                            position: absolute;
                            content: "";
                            width: 6px;
                            height: 6px;
                            border-radius: 100%;
                            background: rgba(29, 29, 36, 0.75);
                            left: 0;
                            top: 10px;
                        }
                        & + li {
                            margin-top: 8px;
                        }
                    }
                }
            }

            
        }
    }
}
.w-88{
    width: 88% !important;
    margin: 15px 6%;
}
.prl-20{
    padding: 0 20px 15px 20px
}
.desserset {
    padding: 0 20px;
}
.desserset p{
    margin-bottom: 15px !important;
}
.desserset h4{
    display: inline-block;
    text-transform: capitalize;
    margin-right: 10px;
    margin-bottom: 5px;
}
.desserset li{
    padding-left: 20px !important;
    margin-bottom: 12px;
}
.desserset li:before{
    display: none;
}
.dmd-devis{
    text-align: center;
    padding: 45px 20px;
}
.sec-one{
    display: inline-block;
    position: relative;
    left: 6%;
    margin-top: 15px;
    @media #{$md-layout} {
        left: 0;
    }
    @media #{$sm-layout} {
        left: 0;
    }
}
.sec-two{
    display: inline-block;
    position: relative;
    left: -6%;
    margin-top: 15px;
    @media #{$md-layout} {
        left: 0;
    }
    @media #{$sm-layout} {
        left: 0;
    }
}
.dmd-devis h2{
    color: #555;
}
.dmd-devis .rn-btn{
    width: 70%;
    height: 50px;
    line-height: 46px;
    font-size: 18px;
    margin-top: 20px;
    @media #{$md-layout} {
        width: 100%;
    }
    @media #{$sm-layout} {
        width: 100%;
    }
}